import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './faqcard.css';

var bCardExpanded = false;

const FaqCard = ({ question, answer }) => {

    const [show, setShow] = React.useState();

    return(
        <div className='faquicontainer'>
            <button onClick={() => setShow(!show)} type="button" className={`faq_btn_expandable ${ show ? "faq_active" : ""}`}>{ question }</button>
            <div className={`faq_btn-content ${ show ? "content_active" : ""}`}>
                <p>{ answer }</p>
            </div>
        </div>
    )
}

export default FaqCard;