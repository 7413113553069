import React from 'react';
import ReactDOM from 'react-dom/client';


import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { Manifesto } from './components/manifesto/Manifesto';
import { Navbar } from './components/navbar/Navbar';
import { Litepaper } from './components/litepaper/Litepaper';
import { ComingSoon } from './components/coming_soon/ComingSoon';
import AboutContent from './container/about-content/AboutContent';
import MainContent from './container/main-content/MainContent';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
const container = document.getElementById('root');
const root = ReactDOM.createRoot( container );

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={ <App /> } />
        <Route
            element={(
              <>
                <Navbar />
                <Outlet />
              </>
            )}
          >
            <Route path='/home' element={<MainContent /> } />
            <Route path="/about" element={<AboutContent />} />
            <Route path="/manifesto" element={<Manifesto />} />
            <Route path="/litepaper" element={<ComingSoon />} />
        </Route>
    </Routes>
  </BrowserRouter> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();