import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import LoadingPage from './components/main_screen/MainScreen';

import './App.css';

function App() {
  return (
    <div className="App">
      <LoadingPage />
    </div>
  );
}

export default App;
