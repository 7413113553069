import React, { Component } from 'react';
import $ from 'jquery'

import TeamCard from '../teamcard/TeamCard';

import weel_pfp from '../../assets/weelsiix-pfp.svg';
import elic_pfp from '../../assets/elicad-pfp.svg';
import belo_pfp from '../../assets/belots-pfp.svg';
import './team.css';

var weelImg = weel_pfp;
var weelName = "@weelsiix";
var weelDesc = "He always dreamt about becoming the best game designer in the world, but he’s taking his time. In the while he tries to learn any dumb utility you could think of.";
var weelTwitter = "https://twitter.com/weelsiix";
var weelInstagram = "https://www.instagram.com/elias_gigantini/";

var ecadImg = elic_pfp;
var ecadName = "@elicad";
var ecadDesc = "One of the biggest psychology nerds out there. You can see her eyes sparkling while she talks about how babies can’t understand that their poop needs to be thrown away and is not their new toy.";
var ecadTwitter = "https://twitter.com/CadeiElisa";
var ecadInstagram = "https://www.instagram.com/elisa__cadei/";

var beloImg = belo_pfp;
var beloName = "@belots238";
var beloDesc = "His mission is to watch and review every movie in history while writing a better script for each one of them, and you better believe he’s going to do it… Maybe during his free time.";
var beloTwitter = null;
var beloInstagram = "https://www.instagram.com/levi.belotti/";



export class Team extends React.Component {
    render() {
        return (
            <div id='team_container' className="team__container">
                <div className='team__container-content'>
                    <div className='team__container-content-main'>
                        <h1 id='tm_h1' className='header-text'>The Team</h1>
                        <div id='team-cards_container' className='team__container-content-main-cards'>
                            <TeamCard 
                                memberImg={weelImg}
                                memberName={weelName}
                                memberDesc={weelDesc}
                                memberTwitter={weelTwitter}
                                memberInstagram={weelInstagram}
                            />
                            <TeamCard 
                                memberImg={ecadImg}
                                memberName={ecadName}
                                memberDesc={ecadDesc}
                                memberTwitter={ecadTwitter}
                                memberInstagram={ecadInstagram}
                            />
                            <TeamCard 
                                memberImg={beloImg}
                                memberName={beloName}
                                memberDesc={beloDesc}
                                memberTwitter={beloTwitter}
                                memberInstagram={beloInstagram}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

document.addEventListener('DOMContentLoaded', function() { 

    // window.addEventListener('scroll', switchNavbarColors);
    window.addEventListener( 'scroll', revealCollectionContent );
    window.addEventListener( 'load', revealCollectionContent );

    
    function revealCollectionContent() {
        var title = document.getElementById("tm_h1");
        var cardsContaienr = document.getElementById("team-cards_container");
        var teamPagePosition = document.getElementById("team_container").offsetTop;

        if( window.pageYOffset >= teamPagePosition * 0.25 ) {
            title.classList.add("fading-in-content-1");
            cardsContaienr.classList.add("fading-in-content-4");
        }
    }
})