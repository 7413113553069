import React, { Component } from 'react';
import $ from 'jquery'

import { Navbar } from '../navbar/Navbar';
import './manifesto.css';


export class Manifesto extends React.Component {
    render() {
        return (
            <div className='manifesto__main__container'>
                <div id='manifesto_container' className="manifesto__container">
                    <div className='manifesto__container-content'>
                        <div className='manifesto__container-content-main'>
                            <h1 id='man_h1' className='header-text fading-in-content-1'>Manifesto</h1>
                            <div id='man_txt' className='body-text fading-in-content-2'>
                            We are built to interact with others: our enemies, our friends and stranger ones.
                            <br />
                            The world around us is a continuos wander full of emotions - some brave and beautiful, 
                            while others funk and disgusting.
                            <br />
                            Development takes time but it starts right now: build connections, break stereotypes al 
                            fulfill yourself.
                            <br />
                            Love whom you want, cry when you need to, scream against the world, and laugh if you’re 
                            feeling high.
                            <br />
                            <br />
                            We are here to stay.
                            <br />
                            We are us.
                            <br />
                            We are Moodin.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}