import React, { Component } from 'react';

import './collectioncard.css';

const CollectionCard = ({ backgroundImg }) => {
    return (
        <div className='nft__card'>
            <img src={backgroundImg} alt='img'></img>
        </div>
    )
}

export default CollectionCard;