import React, { Component } from 'react';
import $ from 'jquery'

import styled from 'styled-components'

import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { BoorgirMenu } from '../boorgirmenu/BoorgirMenu';

import './navbar.css';


var navbar = null;
var sticky = null;

document.addEventListener('DOMContentLoaded', function() {
    // When the event DOMContentLoaded occurs, it is safe to access the DOM
  
    // When the user scrolls the page, execute myFunction 
    window.addEventListener('scroll', toggleStickyNavbar);
  
    // Add the sticky class to the navbar when you reach its scroll position. 
    // Remove "sticky" when you leave the scroll position
  
    function toggleStickyNavbar() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
})

export class Navbar extends React.Component {

    componentDidMount() {
        navbar = document.getElementById("navbar");
        sticky = navbar.offsetTop;
    }

    render() {
        return (
            <div id="navbar" className="navbar__container">
                <div id='navbar_interno' className='navbar__container-interno'>
                    <HashLink to="/home" className='btn_link_no_deco'>
                      <h1 className='logo-text logo fading-in-content-from-top-1'>Moodin</h1>
                    </HashLink>
                    <div className='navbar__container-interno_sections'>
                      <div className='navbar-boorgir'>
                        <BoorgirMenu />
                      </div>
                      <div className='navbar-complete'>
                        <Link to="/about">
                          <button className="link-btn fading-in-content-from-top-4">
                              <p id='navbar-about' className='link-btn_text'>ABOUT</p>
                          </button>
                        </Link>
                        <Link to="/manifesto">
                          <button className="link-btn fading-in-content-from-top-4">
                              <p id='navbar-manifesto' className='link-btn_text'>MANIFESTO</p>
                          </button>
                        </Link>
                        <a href='https://moodin-nft.gitbook.io/litepaper/' target="_blank" rel="noopener noreferrer" className='btn_link_no_deco'>  
                          <button className="litepaper-btn arrow-button-white fading-in-content-from-top-5">LITEPAPER</button>
                        </a>
                        <a href='https://twitter.com/MoodinNFT' target="_blank" rel="noopener noreferrer">  
                          <FontAwesomeIcon icon={faTwitter} className='link-social_icon twitter-icon fading-in-content-from-top-5' />
                        </a>
                        <a href='https://www.instagram.com/moodinnft/' target="_blank" rel="noopener noreferrer">  
                          <FontAwesomeIcon icon={faInstagram} className='link-social_icon instagram-icon fading-in-content-from-top-5' />
                        </a>
                        <a>  
                          <FontAwesomeIcon icon={faDiscord} className='link-social_icon discord-icon fading-in-content-from-top-5 inactive-comp' />
                        </a>
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}

document.addEventListener('DOMContentLoaded', function() { 

  window.addEventListener('scroll', switchNavbarColors);
  window.addEventListener('load', onUrlChange );

  function switchNavbarColors() {

    if( document.location.pathname === "/home" ) {
      var title = document.getElementById("col_h1");
      var subTitle = document.getElementById("col_h2");
      var bodyText = document.getElementById("col_txt");
  
      var collectionPagePosition = document.getElementById("collection_container").offsetTop;
      var faqsPagePosition = document.getElementById("faqs_container").offsetTop;
  
      if( collectionPagePosition && faqsPagePosition ) {
        if( window.pageYOffset >= collectionPagePosition - 150 ) {
            $(document).ready(function() {
              setWhiteNavbar();
            });
        }
  
        if( window.pageYOffset < collectionPagePosition - 150 ) {
            $(document).ready(function() {
              setBlackNavbar();
            });
        }
  
        if( window.pageYOffset >= faqsPagePosition ) {
            $(document).ready(function() {
              setBlackNavbar();
            });
        }
      }
    }
  }


    
})

function setWhiteNavbar() {
  document.documentElement.style.setProperty('--navbar-color', "white");
}

function setBlackNavbar() {
  document.documentElement.style.setProperty('--navbar-color', "black");
}

function setStickyNavbar() {
  document.documentElement.style.setProperty('--navbar-positioning', "sticky");
}

function setAbsoluteNavbar() {
  document.documentElement.style.setProperty('--navbar-positioning', "absolute");
}

function changeNavColorAndPosition() {
  if( window.location.pathname === "/manifesto" ) {
    setWhiteNavbar();
    setAbsoluteNavbar();

    var manifestoBtnToSelect = document.getElementById( "navbar-manifesto" );
    manifestoBtnToSelect.classList.remove( "link-btn_text" );
    manifestoBtnToSelect.classList.add( "active-btn-page" );

    var aboutBtnToDeselect = document.getElementById( "navbar-about" );
    aboutBtnToDeselect.classList.remove( "active-btn-page" );
    aboutBtnToDeselect.classList.add( "link-btn_text" );

  } else if( window.location.pathname === '/about' ) {
    console.log("NAMMOSE IN ABBOUT");
    setBlackNavbar();
    setStickyNavbar();
    
    var aboutBtnToSelect = document.getElementById( "navbar-about" );
    aboutBtnToSelect.classList.remove( "link-btn_text" );
    aboutBtnToSelect.classList.add( "active-btn-page" );
    
    var manifestoBtnToDeselect = document.getElementById( "navbar-manifesto" );
    manifestoBtnToDeselect.classList.remove( "active-btn-page" );
    manifestoBtnToDeselect.classList.add( "link-btn_text" );
  } else {
    console.log(window.location.href);
    setBlackNavbar();
    setStickyNavbar();
    
    var btn1Deselect = document.getElementById( "navbar-about" );
    var btn2Deselect = document.getElementById( "navbar-manifesto" );
    btn1Deselect.classList.remove( "active-btn-page" );
    btn2Deselect.classList.remove( "active-btn-page" );
    btn1Deselect.classList.add( "link-btn_text" );
    btn2Deselect.classList.add( "link-btn_text" );
  }

}

let lastUrl = window.location.href; 
new MutationObserver(() => {
  const url = window.location.href;
  if (url !== lastUrl) {
    lastUrl = url;
    onUrlChange();
  }
}).observe(document, {subtree: true, childList: true});
 
 
function onUrlChange() {
  console.log( window.location.pathname );
  changeNavColorAndPosition();
}