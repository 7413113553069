import { React } from 'react';

import { About } from '../../components/about/About';
import { Team } from '../../components/team/Team';
import { Footer } from '../../components/footer/Footer';

const AboutContent = () => {
    return (
        <div>
            <About />
            <Team />
            <Footer />
        </div>
    )
}

export default AboutContent