import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './teamcard.css';

const TeamCard = ({ memberImg, memberName, memberDesc, memberTwitter, memberInstagram }) => {
    return (
        <div className='team-card__container'>
            <div className='team-card__container__upper'>
                <div className='team-card__image'>
                    <img src={memberImg} alt='img'></img>
                </div>
                {
                    memberTwitter
                    ? 
                    <a href={memberTwitter} target="_blank" rel="noopener noreferrer">  
                        <FontAwesomeIcon icon={faTwitter} className='team-card-icon' />
                    </a>
                    :
                    <></>
                }
                {
                    memberInstagram
                    ? 
                    <a href={memberInstagram} target="_blank" rel="noopener noreferrer">  
                        <FontAwesomeIcon icon={faInstagram} className='team-card-icon' />
                    </a>
                    :
                    <></>
                }
            </div>
            <div className='team-card__container__lower'>
                <p id='tm-mbm'>{memberName}</p>
                <div className='team-card__container__lower-desc'>
                    {memberDesc}
                </div>
            </div>
        </div>
    )
}

export default TeamCard;