import React, { Component } from 'react';
import $ from 'jquery'

import { Link } from "react-router-dom";

import character_img from '../../assets/home_character_L.png';

import './homepage.css';

var title = null;
var subTitle = null;
var bodyText = null;
var litepaperBtn = null;
var characterImage = null;
var homePage = null;
var homepagePosition = null;



export class HomePage extends React.Component {
    
    componentDidMount() {
        title = document.getElementById("hp_h1");
        subTitle = document.getElementById("hp_h2");
        bodyText = document.getElementById("hp_txt");
        litepaperBtn = document.getElementById("hp_btn");
        characterImage = document.getElementById("hp_character");
        homePage = document.getElementById("homepage_container");
        homepagePosition = homePage.offsetTop;
    }
    
    render() {
        return (
            <div id='homepage_container' className="homepage__container">
                <div className='homepage__container-content'>
                    <div className='homepage__container-content-main'>
                        <h1 id='hp_h1' className='header-text'>We Are</h1>
                        <h2 id='hp_h2' className='logo-text'>Moodin</h2>
                        <div id='hp_txt' className='body-text'>
                        Moodin is a new type of multimedia company and our mission is to create amazing 
                        experiences for our community and the entire world.
                        <br/>
                        <br/>
                        A collection of 8.888 extremely emotional avatars that gives you access to <span className='text-highlight'>Moodieland</span>. It 
                        starts with the Moodivault, NFT drops, a high quality clothing line, the Moodin 
                        Complex, a Metaverse building where you can hang out with the community, and much 
                        more.
                        <br/>
                        <br/>
                        You can find all the informations you need by reading our Litepaper. 
                        </div>
                        <a href='https://moodin-nft.gitbook.io/litepaper/' target="_blank" rel="noopener noreferrer" className='btn_link_no_deco'>
                            <button id='hp_btn' className="litepaper-btn arrow-button-white">LITEPAPER</button>
                        </a>
                    </div>
                    <div id='hp_character' className='homepage__container-content-image'>
                        <img src={character_img} alt='img'></img>
                    </div>
                </div>
            </div>
        )
    }
}


document.addEventListener('DOMContentLoaded', function() {
    // When the event DOMContentLoaded occurs, it is safe to access the DOM
  
    // When the user scrolls the page, execute myFunction 
    // window.addEventListener( 'scroll', revealHomeContent );
    window.addEventListener( 'load', revealHomeContent2 );
  
    // Add the sticky class to the navbar when you reach its scroll position. 
    // Remove "sticky" when you leave the scroll position
  
    function revealHomeContent() {

        if( window.pageYOffset >= homepagePosition * 0.75 ) {
            title.classList.add("fading-in-content-1");
            subTitle.classList.add("fading-in-content-2");
            bodyText.classList.add("fading-in-content-3");
            litepaperBtn.classList.add("fading-in-content-4");
            characterImage.classList.add("fading-in-content-5");
        } 
    }
})

function revealHomeContent2() {
    title.classList.add("fading-in-content-1");
    subTitle.classList.add("fading-in-content-2");
    bodyText.classList.add("fading-in-content-3");
    litepaperBtn.classList.add("fading-in-content-4");
    characterImage.classList.add("fading-in-content-5");
}

let lastUrl = window.location.href; 
new MutationObserver(() => {
  const url = window.location.href;
  if (url !== lastUrl) {
    lastUrl = url;
    onUrlChange();
  }
}).observe(document, {subtree: true, childList: true});
 
 
function onUrlChange() {
  console.log( window.location.pathname );
  if( window.location.pathname === "/home" ) {
    revealHomeContent2();
  }
}