import { React } from 'react';
import { HomePage } from '../../components/home_page/HomePage';
import { NftCollection } from '../../components/nft_collection/NftCollection';
import { Distribution } from '../../components/distribution/Distribution';
import { Faqs } from '../../components/faqs/Faqs';
import { Footer } from '../../components/footer/Footer';


const MainContent = () => {
    return (
        <div>
            <HomePage />
            <NftCollection />
            <Distribution />
            <Faqs />
            <Footer />
        </div>
    )
}

export default MainContent