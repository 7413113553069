import React, { Component } from 'react';
import $ from 'jquery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faDiscord } from '@fortawesome/free-brands-svg-icons';

import "./boorgirmenu.css";


export class BoorgirMenu extends React.Component {
    render() {
        return (
            <>
                {/* <!-- We add the checkbox --> */}
                <input type="checkbox" id="hamburger-input" class="burger-shower" />

                {/* We use a `label` element with the `for` attribute 
                with the same value as  our label `id` attribute  */}
                <label id="hamburger-menu" for="hamburger-input">
                    <nav id="sidebar-menu">
                        <h1 className='logo-text logo moodin-brgmn'>Moodin</h1>
                        <ul>
                            <li><a href="/home">Home</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/manifesto">Manifesto</a></li>
                            <li><a href='https://moodin-nft.gitbook.io/litepaper/' target="_blank" rel="noopener noreferrer" className='btn_link_no_deco'>Litepaper</a></li>
                        </ul>
                        <div className='coso'></div>
                        <div className='hamburger__social-icons'>
                            <a href='https://twitter.com/MoodinNFT' target="_blank" rel="noopener noreferrer">  
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href='https://www.instagram.com/moodinnft/' target="_blank" rel="noopener noreferrer">  
                                <FontAwesomeIcon icon={faInstagram}  />
                            </a>
                            <a href='https://twitter.com/MoodinNFT' target="_blank" rel="noopener noreferrer">  
                                <FontAwesomeIcon icon={faDiscord}  />
                            </a>
                        </div>
                    </nav>
                </label>
            </>
        )
    }
}