import { React } from 'react';
import { HashLink } from 'react-router-hash-link';
import './mainscreen.css';

const LoadingPage = () => {
    return (
        <div className="mainscreen__container">
            <div className="mainscreen__container-content">
                <h1 className='logo-text fading-in-content-from-top-1'>MOODIN</h1>
                <div className='mainscreen-subheader body-text fading-in-content-from-top-3'>
                    Welcome to the universe of Moodin where everybody can freely express themselves. Anger, Love,
                    Sadness, Joy, and much more stand as the soundtrack of this amazing journey. Sail with us on this boat 
                    headed to a world full of wonders.
                </div>
                <HashLink to="/home" className='btn_link_no_deco fading-in-content-5'>
                    <button className="mainscreen-button arrow-button-violet">Sail With Us</button>
                </HashLink>
            </div>
        </div>
    )
}

export default LoadingPage