import React, { Component } from 'react';
import $ from 'jquery'

import FaqCard from '../faqcard/FaqCard';

import './faqs.css';

var title = null;
var subTitle = null;
var bodyText = null;
var faqsTitle = null;
var faqsQuestions = null;
var faqsPagePosition = null;

document.addEventListener('DOMContentLoaded', function() { 

    window.addEventListener('scroll', revealDistributionContent);

    function revealDistributionContent() {
        if( window.pageYOffset >= faqsPagePosition * 0.75 ) {
            title.classList.add("fading-in-content-1");
            subTitle.classList.add("fading-in-content-2");
            bodyText.classList.add("fading-in-content-3");

            faqsTitle.classList.add("fading-in-content-1");
            faqsQuestions.classList.add("fading-in-content-3");
        }
        
    }
})

var question1 = "What Is Moodin?"
var question2 = "What Is Our Goal?"
var question3 = "Who Started Moodin?"
var question4 = "When is dropping?"
var question5 = "What Will be The Price?"
let answer1 = "Moodin is a collection of 8.888 extremely emotional NFTs - cryptographic collectibles stored on the Ethereum Blockchain. You can find more by reading our Litepaper.";
var answer2 = "Moodin is a community-focused project centered around making our holders as valueable as possible by creating an environment without distinctions between one another, and giving them back everything we have to offer.";
var answer3 = "Moodin was founded by Weelsiix, a programmer with huge passion for crypto, digital art and videogames. You can find more by reading our Litepaper.";
var answer4 = "TBA"
var answer5 = "The price will be 0.08 ETH for everyone"


export class Faqs extends React.Component {

    componentDidMount() {
        title = document.getElementById("faq_h1");
        subTitle = document.getElementById("faq_h2");
        bodyText = document.getElementById("faq_txt");

        faqsTitle = document.getElementById("faq2_h1");
        faqsQuestions = document.getElementById("faq_questions");

        faqsPagePosition = document.getElementById("faqs_container").offsetTop;
    }

    render() {
        return (
            <div id='faqs_container' className="faqs__container">
               <div className='faqs__container-content'>
                    <div className='faqs__container-content-main'>
                        <div className='faqs__container_headers'>
                            <h1 id='faq_h1' className='header-text'>We !Follow</h1>
                            <h2 id='faq_h2' className='header-text'>The Rules</h2>    
                        </div>
                        <div id='faq_txt' className='body-text'>
                        We are not your ordinary NFT collection. Moodin by Tooin is an innovative brand built 
                        around the community and between the bridge that connects Web2 & Web3 to the
                        physical world.
                        <br />
                        <br />
                        We are not going to follow any trend or rules set by the NFT space. We built everything with 
                        our community in mind first.
                        <br />
                        <br />
                        Be free. Be bold. Be unique.
                        </div>
                    </div>
                    <div id='faqs_separator' className='faqs__separator'></div>
                    <div id='faqs_true' className='faqs__secondary__container'>
                        <h1 id='faq2_h1' className='header-text'>FAQs</h1>
                        <div id='faq_questions' className='faqs__secondary__container-questions'>
                            <FaqCard question={question1} answer={answer1} />
                            <FaqCard question={question2} answer={answer2} />
                            <FaqCard question={question3} answer={answer3} />    
                            <FaqCard question={question4} answer={answer4} />    
                            <FaqCard question={question5} answer={answer5} />    
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}