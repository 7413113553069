import React, { Component } from 'react';
import $ from 'jquery'

import { Navbar } from '../navbar/Navbar';

import globe_spinner from '../../assets/globe-spin.gif';
import './about.css';


export class About extends React.Component {
    render() {
        return (
            <div id='about_container' className="about__container">
                <div className='about__container-content'>
                    <div className='about__container-content-main'>
                        <h1 id='abt_h1' className='header-text fading-in-content-1'>The Project</h1>
                        <div id='abt_txt' className='body-text fading-in-content-2'>
                            Our mission is to build a new kind of multimedia company, that the Moodin community can 
                            partecipate in freely and creatively. With the community’s help, we aim to supply the 
                            world with entertainment, joy and progress.
                            <br />
                            We are a small collective of progressive minds, with innovative ideas and unique talent, 
                            that intend to lead a shifting paradigm towards a world where possibilities are 
                            endless and everybody has a chance to stand up.
                            <br />
                            <br />
                            Together we can.
                        </div>
                    </div>
                    <div id='abt_character' className='about__container-content-image fading-in-content-5'>
                        <img src={globe_spinner} alt='img'></img>
                    </div>
                </div>
            </div>
        )
    }
}