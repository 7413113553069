import React, { Component } from 'react';
import $ from 'jquery'

import CollectionCard from '../collectioncard/CollectionCard';

import nft1 from '../../assets/grid_1_M.png'
import nft2 from '../../assets/grid_2_M.png'
import nft3 from '../../assets/grid_3_M.png'
import nft4 from '../../assets/grid_4_M.png'
import nft5 from '../../assets/grid_5_M.png'
import nft6 from '../../assets/grid_6_M.png'
import nft7 from '../../assets/grid_7_M.png'
import nft8 from '../../assets/grid_8_M.png'
import nft9 from '../../assets/grid_9_M.png'

import './nftcollection.css';

var title = null;
var subTitle = null;
var bodyText = null;
var collectionPagePosition = null;
var faqsPagePosition = null;

document.addEventListener('DOMContentLoaded', function() { 

    // window.addEventListener('scroll', switchNavbarColors);
    window.addEventListener( 'scroll', revealCollectionContent );
    window.addEventListener( 'load', revealCollectionContent );

    function revealCollectionContent() {
        if( window.pageYOffset >= collectionPagePosition * 0.75 ) {
            title.classList.add("fading-in-content-1");
            subTitle.classList.add("fading-in-content-2");
            bodyText.classList.add("fading-in-content-3");

            var nfts = document.getElementsByClassName('nft__card');

            for( let i = 0; i < nfts.length; i++ ) {
                if( i < 3 ) {
                    nfts[i].classList.add("fading-in-content-1");
                } else if( i < 6 ) {
                    nfts[i].classList.add("fading-in-content-2");                    
                } else {
                    nfts[i].classList.add("fading-in-content-3");                    
                }

            }
        }
    }
})


export class NftCollection extends React.Component {

    componentDidMount() {
        title = document.getElementById("col_h1");
        subTitle = document.getElementById("col_h2");
        bodyText = document.getElementById("col_txt");

        collectionPagePosition = document.getElementById("collection_container").offsetTop;
        faqsPagePosition = document.getElementById("faqs_container").offsetTop;
    }

    render() {
        return (
            <div id='collection_container' className="collection__container">
               <div className='collection__container-content'>
                    <div className='collection__container-grid'>
                        <CollectionCard backgroundImg={ nft1 } id='nftcard_1' />
                        <CollectionCard backgroundImg={ nft2 } id='nftcard_2' />
                        <CollectionCard backgroundImg={ nft3 } id='nftcard_3' />
                        <CollectionCard backgroundImg={ nft4 } id='nftcard_4' />
                        <CollectionCard backgroundImg={ nft5 } id='nftcard_5' />
                        <div id='nftcard_6'>
                            <CollectionCard backgroundImg={ nft6 } />
                        </div>
                        <CollectionCard backgroundImg={ nft7 } id='nftcard_7' />
                        <div id='nftcard_8'>
                            <CollectionCard backgroundImg={ nft8 } />
                        </div>
                        <div id='nftcard_9'>
                            <CollectionCard backgroundImg={ nft9 }/>
                        </div>
                    </div>
                    <div className='collection__container-content-main'>
                        <h1 id='col_h1' className='header-text'>The NFT</h1>
                        <h2 id='col_h2' className='header-text'>Collection</h2>
                        <div id='col_txt' className='body-text'>
                        All avatars are unique. We created a custom algorithm to generate our avatars from a  pool 
                        of over 150 traits (+ variants), including ethnicity, eyes, mouth, clothing and much more, for a 
                        total of 1 billion+ possible combinations.
                        <br />
                        The Moodin collection also includes dozens of 1/1s, legendary and unique characters that 
                        distinguish themselves from the others.
                        <br />
                        <br />
                        Owning a Moodin allows you to vote on community-driven features and products, making 
                        our roadmap collaborative and decided by project founders, our team and Moodin holders 
                        via the Moodievault.
                        <br />
                        <br />
                        The Moodievault will be our community treasury, which will be initially funded with 25% of 
                        the initial sale revenue.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}