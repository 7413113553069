import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

import './footer.css';

var logo = null;
var litepaperbtn = null;
var joincommunitybtn = null;
var sections = null;
var socials = null;
var footerPagePosition = null;

document.addEventListener('DOMContentLoaded', function() {

    window.addEventListener('scroll', revealHomeContent);
  
    function revealHomeContent() {

        if( window.pageYOffset >= footerPagePosition * 0.75 ) {
            logo.classList.add("fading-in-content-1");
            litepaperbtn.classList.add("fading-in-content-2");
            joincommunitybtn.classList.add("fading-in-content-3");
            sections.classList.add("fading-in-content-4");
            socials.classList.add("fading-in-content-5");
        } 
    }
})


export class Footer extends React.Component {

    componentDidMount() {
        logo = document.getElementById("fot_logo");
        litepaperbtn = document.getElementById("fot_lbt");
        joincommunitybtn = document.getElementById("fot_jbt");
        sections = document.getElementById("fot_sec");
        socials = document.getElementById("fot_soc");
        footerPagePosition = document.getElementById("fot_container").offsetTop;
    }

    render() {
        return (
            <div id='fot_container' className="footer__container">
                <div className='footer__container-left'>
                    <h1 id='fot_logo' className='logo-text logo-footer'>Moodin @2022</h1>
                    <div className='footer__container-left-buttons'>
                        {/* <Link to="litepaper" className='btn_link_no_deco'>
                          <button id='fot_lbt' className="litepaper-btn arrow-button-white">LITEPAPER</button>
                        </Link> */}
                        <a href='https://moodin-nft.gitbook.io/litepaper/' target="_blank" rel="noopener noreferrer" className='btn_link_no_deco'>
                            <button id='fot_lbt' className="litepaper-btn arrow-button-white">LITEPAPER</button>
                        </a>
                        <a href='https://twitter.com/MoodinNFT' target="_blank" rel="noopener noreferrer" className='btn_link_no_deco'>  
                            <button id='fot_jbt' className="litepaper-btn arrow-button-white join_com_btn">JOIN OUR COMMUNITY</button>
                        </a>
                    </div>
                </div>
                <div className='footer__container-right'>
                    <div id='fot_sec' className='footer__container-right-sections'>
                        <h5>Sections</h5>
                        <a href='/' className='footer-sections-links'>Welcome</a>
                        <a href='#homepage_container' className='footer-sections-links'>Introduction</a>
                        <a href='#collection_container' className='footer-sections-links'>Collection</a>
                        <a href='#distribution_container' className='footer-sections-links'>Distribution</a>
                        <a href='#faqs_container' className='footer-sections-links'>!Rules & FAQs</a>
                    </div>
                    <div id='fot_soc' className='footer__container-right-socials'>
                        <a href='https://twitter.com/MoodinNFT' target="_blank" rel="noopener noreferrer">  
                          <FontAwesomeIcon icon={faTwitter} className='footer_link-social_icon footer-twitter-icon' />
                        </a>
                        <a href='https://www.instagram.com/moodinnft/' target="_blank" rel="noopener noreferrer">  
                          <FontAwesomeIcon icon={faInstagram} className='footer_link-social_icon footer-instagram-icon' />
                        </a>
                        <a>  
                          <FontAwesomeIcon icon={faDiscord} className='footer_link-social_icon footer-discord-icon inactive-comp' />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}