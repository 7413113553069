import React, { Component } from 'react';
import $ from 'jquery'

import { Navbar } from '../navbar/Navbar';
import { Link } from "react-router-dom";

import contentToDownload from '../../assets/distribution_S.png'
import "./litepaper.css";


export class Litepaper extends React.Component {
    render() {
        return (
            <div id='litepaper_container' className="litepaper__container">
                <div className='litepaper__container-left'>
                    <h1 className='logo-text moodin-logo-ltp'>Moodin</h1>
                    <h2 className='header-text moodin-litepaper-ltp'>Litepaper</h2>
                    <Link to="/files/myfiledownload.jpg" target="_blank" download className='btn_link_no_deco'>
                        <button className="download-btn arrow-button-white fading-in-content-from-top-5">DOWNLOAD LITEPAPER</button>
                    </Link>
                </div>
                <div className='litepaper__container-right'>
                    <div className='litepaper-section'>
                        <div className='litepaper-section-inner'>
                            <h1 className='litepaper-header-text moodin-litepaper-ltp'>1. Introduction</h1>
                            <div className='body-text litepaper-descr'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam semper sagittis velit, a dignissim sem suscipit a. 
                            In vehicula turpis quis metus malesuada dignissim. Fusce convallis purus eu magna lacinia vehicula. 
                            In feugiat, odio sed laoreet varius, nisi est lacinia ex, ac sagittis odio ipsum auctor nisi. 
                            Quisque ullamcorper odio id tincidunt vestibulum. Ut semper augue in mi laoreet hendrerit. 
                            Suspendisse elementum sollicitudin nisi at ornare. Vestibulum non condimentum quam. Quisque hendrerit orci et orci cursus, 
                            ac finibus turpis fringilla. Aenean rutrum sit amet orci quis pretium.

                            Sed neque lectus, pulvinar ut risus mollis, consequat semper arcu. Proin nec bibendum orci, 
                            quis sollicitudin ligula. Nulla ac tellus a erat hendrerit pellentesque. Ut egestas, tortor a 
                            finibus pulvinar, purus ante sodales urna, id convallis metus arcu venenatis tortor. 
                            Donec ac lorem vel leo euismod malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Aenean eget elit lacus. Donec a elit quis nisl rutrum venenatis. Suspendisse odio odio, consequat a 
                            tempus ut, pellentesque sit amet lorem. Fusce a imperdiet purus. Donec fermentum ex non velit consectetur, 
                            quis eleifend nunc faucibus. Sed dictum maximus purus, ac consectetur nisi accumsan a.
                            </div>
                        </div>
                    </div>
                    <div className='litepaper-section'>
                        <div className='litepaper-section-inner'>
                            <h1 className='litepaper-header-text moodin-litepaper-ltp'>2. The Problem</h1>
                            <div className='body-text litepaper-descr'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam semper sagittis velit, a dignissim sem suscipit a. 
                            In vehicula turpis quis metus malesuada dignissim. Fusce convallis purus eu magna lacinia vehicula. 
                            In feugiat, odio sed laoreet varius, nisi est lacinia ex, ac sagittis odio ipsum auctor nisi. 
                            Quisque ullamcorper odio id tincidunt vestibulum. Ut semper augue in mi laoreet hendrerit. 
                            Suspendisse elementum sollicitudin nisi at ornare. Vestibulum non condimentum quam. Quisque hendrerit orci et orci cursus, 
                            ac finibus turpis fringilla. Aenean rutrum sit amet orci quis pretium.

                            Sed neque lectus, pulvinar ut risus mollis, consequat semper arcu. Proin nec bibendum orci, 
                            quis sollicitudin ligula. Nulla ac tellus a erat hendrerit pellentesque. Ut egestas, tortor a 
                            finibus pulvinar, purus ante sodales urna, id convallis metus arcu venenatis tortor. 
                            Donec ac lorem vel leo euismod malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Aenean eget elit lacus. Donec a elit quis nisl rutrum venenatis. Suspendisse odio odio, consequat a 
                            tempus ut, pellentesque sit amet lorem. Fusce a imperdiet purus. Donec fermentum ex non velit consectetur, 
                            quis eleifend nunc faucibus. Sed dictum maximus purus, ac consectetur nisi accumsan a.
                            </div>
                        </div>
                    </div>
                    <div className='litepaper-section'>
                        <div className='litepaper-section-inner'>
                            <h1 className='litepaper-header-text moodin-litepaper-ltp'>3. Roadmap</h1>
                            <div className='body-text litepaper-descr'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam semper sagittis velit, a dignissim sem suscipit a. 
                            In vehicula turpis quis metus malesuada dignissim. Fusce convallis purus eu magna lacinia vehicula. 
                            In feugiat, odio sed laoreet varius, nisi est lacinia ex, ac sagittis odio ipsum auctor nisi. 
                            Quisque ullamcorper odio id tincidunt vestibulum. Ut semper augue in mi laoreet hendrerit. 
                            Suspendisse elementum sollicitudin nisi at ornare. Vestibulum non condimentum quam. Quisque hendrerit orci et orci cursus, 
                            ac finibus turpis fringilla. Aenean rutrum sit amet orci quis pretium.

                            Sed neque lectus, pulvinar ut risus mollis, consequat semper arcu. Proin nec bibendum orci, 
                            quis sollicitudin ligula. Nulla ac tellus a erat hendrerit pellentesque. Ut egestas, tortor a 
                            finibus pulvinar, purus ante sodales urna, id convallis metus arcu venenatis tortor. 
                            Donec ac lorem vel leo euismod malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Aenean eget elit lacus. Donec a elit quis nisl rutrum venenatis. Suspendisse odio odio, consequat a 
                            tempus ut, pellentesque sit amet lorem. Fusce a imperdiet purus. Donec fermentum ex non velit consectetur, 
                            quis eleifend nunc faucibus. Sed dictum maximus purus, ac consectetur nisi accumsan a.
                            </div>
                        </div>
                    </div>
                    <div className='litepaper-section'>
                        <div className='litepaper-section-inner'>
                            <h1 className='litepaper-header-text moodin-litepaper-ltp'>4. Holders Benefits</h1>
                            <div className='body-text litepaper-descr'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam semper sagittis velit, a dignissim sem suscipit a. 
                            In vehicula turpis quis metus malesuada dignissim. Fusce convallis purus eu magna lacinia vehicula. 
                            In feugiat, odio sed laoreet varius, nisi est lacinia ex, ac sagittis odio ipsum auctor nisi. 
                            Quisque ullamcorper odio id tincidunt vestibulum. Ut semper augue in mi laoreet hendrerit. 
                            Suspendisse elementum sollicitudin nisi at ornare. Vestibulum non condimentum quam. Quisque hendrerit orci et orci cursus, 
                            ac finibus turpis fringilla. Aenean rutrum sit amet orci quis pretium.

                            Sed neque lectus, pulvinar ut risus mollis, consequat semper arcu. Proin nec bibendum orci, 
                            quis sollicitudin ligula. Nulla ac tellus a erat hendrerit pellentesque. Ut egestas, tortor a 
                            finibus pulvinar, purus ante sodales urna, id convallis metus arcu venenatis tortor. 
                            Donec ac lorem vel leo euismod malesuada. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Aenean eget elit lacus. Donec a elit quis nisl rutrum venenatis. Suspendisse odio odio, consequat a 
                            tempus ut, pellentesque sit amet lorem. Fusce a imperdiet purus. Donec fermentum ex non velit consectetur, 
                            quis eleifend nunc faucibus. Sed dictum maximus purus, ac consectetur nisi accumsan a.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}