import React, { Component } from 'react';
import $ from 'jquery'

import { Link } from "react-router-dom";

import "./comingsoon.css";


export class ComingSoon extends React.Component {
    render() {
        return (
            <div id='comingsoon_container' className="comingsoon__container">
                <div className='logo-text coming-soon-text fading-in-content-1'>
                    COMING
                    <br />
                    SOON...
                </div>
            </div>
        )
    }
}