import React, { Component } from 'react';
import $ from 'jquery'

import DistributionChart from '../../assets/distribution_M.png';

import './distribution.css';

var title = null;
var subTitle = null;
var bodyText = null;
var chart = null;
var distributionPagePosition = null;

document.addEventListener('DOMContentLoaded', function() { 

    window.addEventListener('scroll', revealDistributionContent);

    function revealDistributionContent() {
        if( window.pageYOffset >= distributionPagePosition * 0.75 ) {
            title.classList.add("fading-in-content-1");
            subTitle.classList.add("fading-in-content-2");
            bodyText.classList.add("fading-in-content-3");
            chart.classList.add("fading-in-content-3");
        }
    }
})


export class Distribution extends React.Component {

    componentDidMount() {
        title = document.getElementById("dis_h1");
        subTitle = document.getElementById("dis_h2");
        bodyText = document.getElementById("dis_txt");
        chart = document.getElementById("dis_chart");

        distributionPagePosition = document.getElementById("distribution_container").offsetTop;
    }

    render() {
        return (
            <div id='distribution_container' className="distribution__container">
               <div className='distribution__container-content'>
                    <div className='distribution__container-content-main'>
                        <h1 id='dis_h1' className='header-text'>Distribution</h1>
                        <h2 id='dis_h2' className='header-text'>Mechanics</h2>
                        <div id='dis_txt' className='body-text'>
                        We studied our distribution mechanic to reward our community, our early supporters, and all 
                        the team that put blood and sweat into Moodin. For those who are still doubtful or just 
                        discovered the project, don’t worry, you’ll still have a chance to be part of this big family.
                        <br />
                        <br />
                        The <span className='text-highlight'>pre-sale</span> phase will reward our earliest supporters, and it will be opened exclusively to 
                        the Whitelist.
                        <br />
                        <span className='text-lowop'>*any unredeemed spots will be automatically re-assigned into the public sale</span>
                        <br />
                        <br />
                        The distribution method for the <span className='text-highlight-blue'>public sale</span> is still TBD. It will be one between Raffle or Standard 
                        and open to all.
                        <br />
                        <br />
                        We will reserve 144 NFTs for the <span className='text-highlight-rose'>team</span> and <span className='text-highlight-rose'>community</span>.
                        </div>
                    </div>
                    <div id='dis_chart' className='distribution__container-content-image'>
                        <img src={DistributionChart} alt='img'></img>
                    </div>
                </div>
            </div>
        )
    }
}